import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase/app";
import "firebase/auth";
import { db } from "../main";
import router from "../router/index.js";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    snackbar: {},
    userToken: "",
    userId: "",
    userInfo: {},
    bookmarks: [],
    loading: false,
    needUpdate: false,
    password: "",
    visibles: [],
    formLink: "",
  },
  mutations: {
    SET_SNACKBAR(state, snackbar) {
      state.snackbar = snackbar;
    },
    setUpdateWaiting(state) {
      state.userInfo.waiting = true;
    },
    setUpdate(state, payload) {
      state.needUpdate = payload;
    },
    setProfileInfo(state, doc) {
      state.userInfo = doc.data();
      state.loading = false;
    },
    setBookmarks(state, bookmarks) {
      state.userInfo.bookmarks = bookmarks;
    },
    setUserToken(state, token) {
      state.userToken = token;
      state.userId = token.user_id;
    },
    addShelf(state, bookId) {
      state.userInfo.bookmarks.push(bookId);
    },
    removeShelf(state, bookId) {
      const index = state.userInfo.bookmarks.indexOf(bookId);
      if (index > -1) {
        state.userInfo.bookmarks.splice(index, 1);
      }
    },
    updateBookmarks(state, payload) {
      state.bookmarks = payload;
    },
    updateLoading(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    setSnackbar({ commit }, snackbar) {
      snackbar.showing = true;
      commit("SET_SNACKBAR", snackbar);
    },
    setVisibles({ state }, array) {
      state.visibles = array;
    },
    async getCurrentUser({ commit, state }) {
      commit("updateLoading", true);
      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then(async (tokenResult) => {
          commit("setUserToken", tokenResult.claims);
          if (tokenResult.claims.email.split("@")[1] != "up.edu.ph") {
            firebase.auth().signOut();
          }
        })
        .then(async () => {
          const dataBase = db
            .collection("users")
            .doc(firebase.auth().currentUser.uid);
          const dbResults = await dataBase.get();
          state.userInfo = dbResults.data();
          if (
            !dbResults.data().institution &&
            dbResults.data().valid == true &&
            dbResults.data().waiting == false
          ) {
            router.push({ name: "Profile" });
            state.needUpdate = true;
          }
        });
      commit("updateLoading", false);
    },

    async getUserInfo({ commit, state }) {
      commit("updateLoading", true);
      const dataBase = db.collection("users").doc(state.userId);
      const dbResults = await dataBase.get();
      commit("setProfileInfo", dbResults);
      commit("updateLoading", false);
    },

    async addToShelf({ commit, state, dispatch }, id) {
      commit("updateLoading", true);
      const dataBase = db.collection("users").doc(state.userId);
      await dataBase
        .update({
          bookmarks: firebase.firestore.FieldValue.arrayUnion(id),
        })
        .then(() => {
          dispatch("setSnackbar", {
            text: "Added to your Shelf!",
            icon: "mdi-check",
            iconColor: "success",
          });
          commit("updateLoading", false);
        });
      commit("addShelf", id);
    },

    async removeFromShelf({ commit, state, dispatch }, id) {
      commit("updateLoading", true);
      const dataBase = await db.collection("users").doc(state.userId);
      await dataBase
        .update({
          bookmarks: firebase.firestore.FieldValue.arrayRemove(id),
        })
        .then(() => {
          dispatch("setSnackbar", {
            text: "Book was removed from your Shelf!",
            icon: "mdi-delete",
            iconColor: "error",
          });
          commit("updateLoading", false);
        });
      commit("removeShelf", id);
    },

    async getBookmarks({ commit }) {
      const dataBase = db
        .collection("users")
        .doc(firebase.auth().currentUser.uid);
      const bookmarks = await dataBase.get();
      commit("setBookmarks", bookmarks.data().bookmarks);
    },
    async getPasswordAndFormLink({ state }) {
      const dataBase = db.collection("authors").doc("0kQqdqS6PhsmHV4ih5Hf");
      const staticRecords = await dataBase.get();
      state.password = staticRecords.data().password;
      state.formLink = staticRecords.data().formLink;
    },
  },
  modules: {},
});
