import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#010073",
        secondary: "#34338f",
        tertiary: colors.grey.lighten2,
        accent: colors.grey.lighten4,
        background: "#f3f3f3",
        highlight: "#1b3d58",
      },
    },
  },
});
