<template lang="pug">
v-app(style="background: #f3f3f3")
  v-main
    router-view(:key="$route.path")
  v-snackbar(v-model="snackbar.showing", transition="scroll-y-transition" timeout="7000").pb-15.pb-md-5
    span 
      v-icon.mt-n1(:color="snackbar.iconColor") {{ snackbar.icon }}
    span.ml-1 {{ snackbar.text }}
    template(v-slot:action="{ attrs }")
      v-btn(
        color="error",
        text,
        v-bind="attrs",
        @click="snackbar.showing = false"
      ) Close
</template>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
<script>
import Vue from "vue";
import "bootstrap/dist/css/bootstrap.css";
import { mapState } from "vuex";
import VuePageTitle from "vue-page-title";
import firebase from "firebase/app";
import "firebase/auth";

Vue.use(VuePageTitle, {
  suffix: "| CS Libraries eBook Hub",
});
export default {
  name: "App",
  computed: {
    ...mapState(["snackbar"]),
  },
  watch: {
    $route: {
      handler: (to, from) => {
        document.title = to.meta.title || "CS Libraries eBook Hub";
      },
      immediate: true,
    },
  },
  async created() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.$store.dispatch("getCurrentUser");
        if (!localStorage.expire) {
          localStorage.expire = Number(Date.now()) + 172800000;
        } else if (localStorage.expire < Number(Date.now())) {
          localStorage.removeItem("expire");
          firebase.auth().signOut();
        }
      }
    });
  },
};
</script>

<style>
html {
  font-size: 16px !important;
  font-family: "Roboto", sans-serif !important;
}
</style>
