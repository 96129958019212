import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";
import { firestorePlugin } from "vuefire";
import FlashMessage from "@smartweb/vue-flash-message";
import InstantSearch from "vue-instantsearch";
import VueCryptojs from "vue-cryptojs";
Vue.use(VueCryptojs);
Vue.use(firestorePlugin);
Vue.use(FlashMessage);
Vue.use(InstantSearch);

Vue.config.productionTip = false;
var firebaseConfig = {
  apiKey: "AIzaSyCXp0ADopWlpUcPybeINRZpU_O3yAq4Fqk",
  authDomain: "cs-lib.firebaseapp.com",
  databaseURL: "https://cs-lib.firebaseio.com",
  projectId: "cs-lib",
  storageBucket: "cs-lib.appspot.com",
  messagingSenderId: "837459006480",
  appId: "1:837459006480:web:c6f840954ae5dbf848a695",
  measurementId: "G-2YQCPFFP7W",
};

// Initialize Firebase
export const db = firebase.initializeApp(firebaseConfig).firestore();

const { Timestamp, Geopoint } = firebase.firestore;
export { Timestamp, Geopoint };

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
