import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import firebase from "firebase/app";
import "firebase/auth";
import VuePageTitle from "vue-page-title";
Vue.use(VuePageTitle);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "",
      meta: {
        title: "Login | CS Libraries eBook Hub",
      },
      name: "Login",
      component: () => import("../views/Login.vue"),
      beforeEnter: async (to, from, next) => {
        firebase.auth().onAuthStateChanged((userAuth) => {
          if (userAuth) {
            firebase
              .auth()
              ?.currentUser?.getIdTokenResult()
              .then((tokenResult) => {
                if (tokenResult.claims.renewed == false) {
                  return next({
                    path: "/account-renewal",
                  });
                } else if (tokenResult.claims.valid == true) {
                  return next({
                    path: "/user/home",
                  });
                } else {
                  return next({
                    path: "/account-management",
                  });
                }
              });
          } else {
            next();
          }
        });
      },
    },

    {
      path: "/book/:id",
      meta: {
        title: "PDF File | CS Libraries eBook Hub",
        auth: true,
      },
      name: "Book",
      component: () => import("../views/Book.vue"),
      props: true,
    },

    {
      path: "/account-management",
      name: "invalid",
      component: () => import("../views/Invalid.vue"),
      meta: {
        title: "Account Validation | CS Libraries eBook Hub",
        auth: true,
      },
      beforeEnter: async (to, from, next) => {
        firebase
          .auth()
          ?.currentUser?.getIdTokenResult()
          .then((tokenResult) => {
            if (tokenResult.claims.valid != true) {
              return next();
            }
          });
      },
    },
    {
      path: "/account-renewal",
      name: "renewal",
      component: () => import("../views/Renewal.vue"),
      meta: {
        title: "Account Renewal | CS Libraries eBook Hub",
        auth: true,
      },
      beforeEnter: async (to, from, next) => {
        firebase
          .auth()
          ?.currentUser?.getIdTokenResult()
          .then((tokenResult) => {
            if (tokenResult.claims.renewed == false) {
              return next();
            } else {
              return next({
                path: "/user/home",
              });
            }
          });
      },
    },

    {
      path: "/results",
      component: () => import("../components/Results.vue"),
      name: "Results",
      meta: {
        title: "Results | CS Libraries eBook Hub",
        auth: true,
      },
      children: [
        {
          path: "search",
          name: "Search",
          component: () => import("../views/Search.vue"),
          meta: {
            title: "Search | CS Libraries eBook Hub",
            auth: true,
          },
        },
      ],
    },
    {
      path: "/user",
      component: () => import("../components/Navbar.vue"),
      name: "Navigation",
      meta: {
        auth: true,
      },
      children: [
        {
          path: "home",
          name: "Home",
          component: () => import("../views/Home.vue"),
          meta: {
            auth: true,
            title: "CS Libraries eBook Hub",
          },
        },
        {
          path: "shelf",
          name: "Shelf",
          component: () => import("../views/Shelf.vue"),
          meta: {
            auth: true,
            title: "My Shelf | CS Libraries eBook Hub",
          },
        },
        {
          path: "book-item/:id",
          name: "Book-item",
          component: () => import("../views/ShelfItem.vue"),
          props: true,
          meta: {
            auth: true,
            title: "Book Details | CS Libraries eBook Hub",
          },
        },
        {
          path: "profile",
          name: "Profile",
          component: () => import("../views/Profile.vue"),
          meta: {
            auth: true,
            title: "My Profile | CS Libraries eBook Hub",
          },
        },
        {
          path: "course-references/:slug",
          name: "CourseReferences",
          component: () => import("../views/CourseReference.vue"),
          meta: {
            auth: true,
            title: "Course References | CS Libraries eBook Hub",
          },
        },
        {
          path: "syllabi/:slug",
          name: "Syllabi",
          component: () => import("../views/Syllabi.vue"),
          meta: {
            auth: true,
            title: "Institute Syllabi | CS Libraries eBook Hub",
          },
        },
        {
          path: "subject/:slug",
          name: "Subject",
          component: () => import("../views/Subject.vue"),
          meta: {
            auth: true,
            title: "Subject | CS Libraries eBook Hub",
          },
        },
      ],
      beforeEnter: async (to, from, next) => {
        firebase
          .auth()
          ?.currentUser?.getIdTokenResult()
          .then((tokenResult) => {
            if (tokenResult.claims.valid == true) {
              return next();
            } else {
              return next({
                path: "/",
              });
            }
          });
      },
    },
    {
      path: "/admin",
      component: () => import("../components/Admin.vue"),
      children: [
        {
          path: "",
          name: "AdminDashboard",
          component: () => import("../views/AdminDashboardtest.vue"),
          meta: {
            admin: true,
            title: "Admin Dashboard | CS Libraries eBook Hub",
          },
        },
        {
          path: "edit",
          name: "Edit",
          component: () => import("../views/Edit.vue"),
          meta: {
            admin: true,
            title: "Edit Frontend | CS Libraries eBook Hub",
          },
        },
        {
          path: "books",
          name: "ManageBooks",
          component: () => import("../views/ManageBooks.vue"),
          meta: {
            admin: true,
            title: "Manage Books | CS Libraries eBook Hub",
          },
        },
        {
          path: "edit-book/:id",
          name: "EditBook",
          component: () => import("../views/EditBook.vue"),
          props: true,
          meta: {
            admin: true,
            title: "Edit Book Details | CS Libraries eBook Hub",
          },
        },
        {
          path: "users",
          name: "ManageUsers",
          component: () => import("../views/ManageUsers.vue"),
          meta: {
            admin: true,
            title: "Manage Users | CS Libraries eBook Hub",
          },
        },
        {
          path: "notifications",
          name: "Notifications",
          component: () => import("../views/Notifications.vue"),
          meta: {
            admin: true,
            title: "Notifications | CS Libraries eBook Hub",
          },
        },
        {
          path: "course-references",
          name: "EditCourseReferences",
          component: () => import("../views/EditCourseReference.vue"),
          meta: {
            admin: true,
            title: "Edit Course References | CS Libraries eBook Hub",
          },
        },
      ],
      meta: {
        title: "CS Lib eBook Hub",
        auth: true,
      },

      beforeEnter: async (to, from, next) => {
        firebase
          .auth()
          ?.currentUser?.getIdTokenResult()
          .then((tokenResult) => {
            if (
              tokenResult.claims.type == "admin" &&
              tokenResult.claims.admin
            ) {
              return next();
            } else {
              return next({
                path: "/user/home",
              });
            }
          });
      },
    },
    {
      path: "*",
      name: "404",
      component: () => import("../views/404.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged((userAuth) => {
    if (userAuth) {
      next();
    } else {
      if (to.matched.some((record) => record.meta.auth)) {
        next({
          path: "/",
        });
      } else {
        next();
      }
    }
  });

  next();
});

export default router;
